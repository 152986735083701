import React from "react";
import { graphql } from "gatsby";
import tw, { styled, css } from "twin.macro";
import "styled-components/macro";
import _ from "lodash";

import moment from "moment-timezone";
import Layout from "../components/Layout";

import WidgetCard from "@shared/components/WidgetCard";
import TopPerformersWidget from "@shared/components/TopPerformersWidget";
import InningStatsWidget from "@shared/components/InningStatsWidget";
import VersusWidget from "@shared/components/VersusWidget";
import Icon from "@shared/components/Icon";
import { TZ } from "@shared/constants";
import useStats from "@shared/hooks/useStats";
import useTeams from "@shared/hooks/useTeams";
import { hasEmptyDeep, hasPassed } from "@shared/utils";

import { HOME_TEAMS, MAIN_COLOR, SITE } from "../config";
import { useSiteFilter } from "../context/SiteFilterContext";

const Section = styled.div`
  ${tw`mb-6 grid gap-6`}

  ${({ childrenCount }) =>
    childrenCount &&
    css`
      @media (min-width: 1024px) {
        grid-template-columns: repeat(${childrenCount}, 1fr);
      }
    `}
`;

const LiveButtonContainer = styled.a`
  ${tw`
    bg-[#EC1F27] text-white 
    flex items-center justify-center
    px-4 py-0.5 my-4
    text-sm font-bold 
    hover:not-disabled:opacity-80 transition-opacity 
    rounded-xl
  `}

  & > .material-icons {
    ${tw`mr-2 text-4xl`}
  }
`;

const Body = styled.div`
  ${tw`prose max-w-full`}

  > *:first-child {
    ${tw`mt-0`}
  }

  iframe {
    ${tw`w-full`}
  }
`;

const GamePost = ({
  data: {
    markdownRemark: {
      id,
      html,
      frontmatter: {
        seo,
        homeTeam: home,
        awayTeam: away,
        date,
        showBuyTicket,
        ticketLink,
        promoGame,
        stats,
        field,
      },
    },
    teamsData: { nodes: teamNodes },
    playersData: { nodes: playerNodes },
    homepage,
  },
  location,
}) => {
  const momentDate = moment.tz(date, TZ);
  const timePassed = moment.tz(TZ).diff(momentDate, "minutes");
  const isCompletedGame = timePassed > 180;

  const { homeTeam: currentHomeTeam } = useSiteFilter();
  const { inningStats, pitchingStats, topPerformers, finalScore } = stats || {};

  const { inningRuns, score, hits, errors } = useStats({
    inningStats,
  });
  const { homeTeam, awayTeam } = useTeams({ teamNodes, home, away });

  // check if any of fields is empty, display is set to false
  const pitchingStatsDisplayArr = Object.keys(pitchingStats).map(
    (key) => !hasEmptyDeep(pitchingStats[key])
  );
  const showPitchingStatsSection = pitchingStatsDisplayArr.some((bool) => bool);
  const showInningStatsSection = !hasEmptyDeep([inningStats]);
  const showInningStatsWidget =
    showPitchingStatsSection || showInningStatsSection;

  const showTopPerformers = !hasEmptyDeep([topPerformers]);

  const visibleCount = [showInningStatsWidget, showTopPerformers].filter(
    (bool) => bool
  ).length;
  const currentHomeTeamDoc = _.find(
    teamNodes,
    (node) => _.toLower(node.frontmatter.name) === currentHomeTeam
  );
  const watchLiveLink = _.get(
    currentHomeTeamDoc,
    "frontmatter.watchLiveLink",
    ""
  );
  const teamSeasonLink = _.get(
    currentHomeTeamDoc,
    "frontmatter.teamSeasonLink",
    ""
  );
  const homeTeams = currentHomeTeam ? [currentHomeTeam] : HOME_TEAMS;
  return (
    <Layout
      noBg
      location={location}
      seoTitle={`${away} vs ${home} (${moment(date)
        .tz(TZ)
        .format("MMM D, YYYY h:mma")})`}
      useSeoForTitle
    >
      <Section>
        <VersusWidget
          score={
            isCompletedGame && !hasEmptyDeep(finalScore) ? finalScore : score
          }
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          date={date}
          leagueNodes={[]}
          showBuyTicket={showBuyTicket}
          ticketLink={ticketLink}
          site={SITE}
          color={MAIN_COLOR}
          homeTeams={homeTeams}
          field={field}
        />
      </Section>
      {hasEmptyDeep(score) && !hasPassed(date) && watchLiveLink && (
        <Section>
          <LiveButtonContainer
            href={watchLiveLink}
            target="_blank"
            disabled={_.isEmpty(watchLiveLink)}
          >
            <Icon name="videocam" /> <div>WATCH NOW</div>
          </LiveButtonContainer>
        </Section>
      )}

      {(showInningStatsWidget || showTopPerformers) && (
        <Section childrenCount={visibleCount}>
          {showInningStatsWidget && (
            <InningStatsWidget
              score={score}
              inningRuns={inningRuns}
              hits={hits}
              errors={errors}
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              pitchingStats={pitchingStats}
              showPitchingStatsSection={showPitchingStatsSection}
              showInningStatsSection={showInningStatsSection}
            />
          )}
          {showTopPerformers && (
            <TopPerformersWidget
              players={playerNodes}
              topPerformers={topPerformers}
            />
          )}
        </Section>
      )}
      {html && (
        <Section>
          <WidgetCard>
            <Body dangerouslySetInnerHTML={{ __html: html }} />
          </WidgetCard>
        </Section>
      )}
    </Layout>
  );
};

export default GamePost;

export const pageQuery = graphql`
  query GamePostQuery(
    $id: String!
    $teams: [String]
    $topPerformers: [String]
  ) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        seo {
          title
          description
        }
        homeTeam
        awayTeam
        date
        # showBuyTicket
        # ticketLink
        # promoGame
        field
        stats {
          finalScore {
            away
            home
          }
          topPerformers {
            player
            upperStats
            lowerStats
          }
          inningStats {
            home
            away
          }
          pitchingStats {
            win {
              player
              seasonRecord {
                wins
                losses
              }
              era
            }
            loss {
              player
              seasonRecord {
                wins
                losses
              }
              era
            }
            save {
              player
              seasonRecord {
                wins
                losses
              }
              era
            }
          }
        }
      }
    }
    teamsData: allMarkdownRemark(
      filter: { frontmatter: { mdType: { eq: "team" }, name: { in: $teams } } }
    ) {
      nodes {
        frontmatter {
          name
          logo {
            childImageSharp {
              gatsbyImageData(height: 75)
            }
          }
          location {
            abbreviation
            fieldName
            address
          }
          watchLiveLink
          teamSeasonLink
        }
      }
    }
    playersData: allMarkdownRemark(
      filter: { fields: { slug: { in: $topPerformers } } }
    ) {
      nodes {
        frontmatter {
          name
          team
          # avatar {
          #   childImageSharp {
          #     gatsbyImageData(layout: FULL_WIDTH)
          #   }
          # }
        }
        fields {
          slug
        }
      }
    }
  }
`;
